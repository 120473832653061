import { useState, useEffect } from "react";
import { Link } from "react-scroll";

import Logo1 from "../../assets/img/Repair/logo-expand-rouge123.jpg";

export default function Header() {
  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div
      className="z-50 w-full border-b-[1px] bg-white border-borderColor fixed top-0 px-6 fill-mode-forwards"
      id="Navbar"
    >
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center  py-2 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={"First_Home"} spy={true} smooth={true} offset={0} duration={500}>
              <img className="w-32 sm:w-64 cursor-pointer transition-all duration-300" src={Logo1} alt="" />
            </Link>
          </div>
          <div className="md:hidden  opacity-100">
            <button
              type="button"
              className="rounded-md relative inline-flex items-center justify-center text-[black]   focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav className="hidden md:flex space-x-6 md:items-center">
            {/* <Link
                to='First_Home'
                activeClass='Active'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className='header-link'
              >
                Home
              </Link> */}
            <Link
              activeClass="Active"
              to="About"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="header-link"
            >
              About
            </Link>
            <Link
              activeClass="Active"
              to="Service"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="header-link"
            >
              Services
            </Link>
            <Link
              activeClass="Active"
              to="Book"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="header-link"
            >
              Book
            </Link>
            <Link
              activeClass="Active"
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="header-link"
            >
              Contact
            </Link>
          </nav>
        </div>
      </div>
      <div
        className={
          open
            ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-24 px-6 inset-x-0 p-2  transform origin-top-right md:hidden  z-50"
            : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        }
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 relative z-50 ">
          <div className="pt-5 pb-6 px-5 ">
            <div className="flex items-center justify-between">
              <div>
                <Link to={"First_Home"} spy={true} smooth={true} offset={0} duration={500}>
                  <img className="h-8 w-auto cursor-pointer" src={Logo1} alt="" />
                </Link>
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className=" rounded-md  text-black  focus:outline-none focus:ring-0 focus:ring-inset "
                  onClick={() => setOpen(!open)}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="grid grid-cols-1 gap-y-4 gap-x-8">
              {/* <Link
                  to='First_Home'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className='header-link'
                >
                  Home
                </Link> */}
              <Link to="About" spy={true} smooth={true} offset={0} duration={500} className="header-link">
                About
              </Link>
              <Link to="Service" spy={true} smooth={true} offset={0} duration={500} className="header-link">
                Services
              </Link>
              <Link to="Book" spy={true} smooth={true} offset={0} duration={500} className="header-link">
                Book
              </Link>
              <Link to="contact" spy={true} smooth={true} offset={0} duration={500} className="header-link">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
