import Slider from "react-slick";

import OpinionSliderProps from "./PropsComponents/OpinionSliderProps";
import Title from "./PropsComponents/Title";

export default function HomeOpinion() {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  const SliderData = [
    {
      description:
        "Our refrigerator was not cooling, so I called Perfect Repair Hayk was able to come the same day. He immediately knew what the problem was and was able to repair it in less than an hour. He did an excellent job and answered all the questions I had, and how to maintain it in the future. I would highly recommend this company",
      author: "Mary B.",
    },
    {
      description:
        "Very professional and prompt, with truly the best prices. I needed a repair done the same day at short notice and Hayk accommodated and got it done! Answered my message right away,and didn’t charge me rush fee (as a college student this was so appreciated!!). Even gave me advice on how to avoid having the same issue in the future, just great service all around! I can’t recommend them enough",
      author: " Maggie P.",
    },
    {
      description:
        "Such a pleasant and knowledgeable person. He did a great job at my place. Was very quick, clean. and professional. Overall great experience. Highly recommend using Hayks services.",
      author: "Irina A.",
    },
    {
      description:
        " I called Hayk for repair on our appliance. He was not only on time for service but the level of attention he gave to the work was like non-other. He took the time to explain our issue, he gave us alternatives and advice that was reliable.He not only took the time to educate us but also gave us all the variables to save money. If you’re looking for a professional, reliable and trustworthy repairman, he’s your guy.",
      author: "Rose C.",
    },
    {
      description:
        "  I am so happy i choose perfect repair. Very professional , time management and quality work. I would definitely recommend this company.",
      author: "Hasmik M.",
    },
    {
      description:
        "Best service ever! Very professional and helpful. I am so happy I chose Perfect Repair. I will definitely chose them every time.",
      author: "Jessica M.",
    },
    {
      description:
        "    Very professional, great prices and job completed in a timely manner. I would definitely recommend them. My fridge now works without any problem. Thanks for great and awesome service.",
      author: "Anahit G.",
    },
    {
      description: " Quick and productive",
      author: "Ash K.",
    },
    {
      description: "Perfect job",
      author: "Lilit G.",
    },
    {
      description: "Great job!",
      author: "David M.",
    },
  ];

  return (
    <div className="bg-bg-image Opinion__Section relative after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[rgba(0,0,0,.6)] after:z-0 Block__Padding bg-cover bg-fixed">
      <div className="relative z-20 text-white">
        <Title title="Customer Testimonials" />
      </div>
      <div className="max-w-2xl mx-auto relative z-50 mb-[20px]">
        <Slider {...settings}>
          {SliderData &&
            SliderData.map((item, index) => {
              return <OpinionSliderProps description={item.description} author={item.author} key={index} />;
            })}
        </Slider>
      </div>
    </div>
  );
}
