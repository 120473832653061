import React from "react";

import HomeAbout from "./Components/homeAbout";
import HomeContact from "./Components/homeContact";
import HomeFirst from "./Components/homeFirst";
import HomeOffer from "./Components/homeOffer";
import HomeOpinion from "./Components/homeOpinion";
import HomePrice from "./Components/homePrice";
import HomeService from "./Components/homeService";
import HomeWorks from "./Components/HomeWorks";

export default function Home() {
  return (
    <>
      <HomeFirst />
      <HomeAbout />
      <HomeService />
      <HomeContact />
      <HomeOpinion />
      <HomePrice />
      <HomeWorks />
      <HomeOffer />
    </>
  );
}
