import PriceSmall from "./priceSmall";
import Title from "./PropsComponents/Title";

export default function HomePrice() {
  return (
    <div id="Pricing" className="Block__Padding bg-[#F5EEE7]">
      <div className="max-w-6xl mx-auto  max-xl:px-6">
        <div className="text-main">
          <Title title={"Coverage Area"} />
        </div>
        <div className="flex max-md:flex-wrap md:space-x-6">
          <div className="w-full sm:w-1/2 md:w-1/3 px-2  space-y-1">
            <PriceSmall title={"Alhambra"} />
            <PriceSmall title={"Arcadia"} />
            <PriceSmall title={"Beverly Hills"} />
            <PriceSmall title={"Burbank"} />
            <PriceSmall title={"Calabasas"} />
            <PriceSmall title={"Culver City"} />
            <PriceSmall title={"Glendale"} />
            <PriceSmall title={"Hermosa Beach"} />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 px-2  space-y-1">
            <PriceSmall title={"Hidden Hills"} />
            <PriceSmall title={"La Cañada Flintridge"} />
            <PriceSmall title={"Long Beach"} />
            <PriceSmall title={"Los Angeles"} />
            <PriceSmall title={"Malibu"} />
            <PriceSmall title={"Manhattan Beach"} />
            <PriceSmall title={"Monrovia"} />
            <PriceSmall title={"Palos Verdes Estates"} />
          </div>
          <div className="w-full md:w-1/3 px-2  space-y-1">
            <PriceSmall title={"Pasadena"} />
            <PriceSmall title={"Rancho Palos Verdes"} />
            <PriceSmall title={"Redondo Beach"} />
            <PriceSmall title={"San Marino"} />
            <PriceSmall title={"Santa Monica"} />
            <PriceSmall title={"Sierra Madre"} />
            <PriceSmall title={"South Pasadena"} />
            <PriceSmall title={"West Hollywood"} />
          </div>
        </div>
      </div>
    </div>
  );
}
