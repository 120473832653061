import { AiOutlineCheck } from "react-icons/ai";

import Title from "./PropsComponents/Title";

export default function HomeAbout() {
  return (
    <div className="w-full Block__Padding  bg-[#F5EEE7]" id="About">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row max-xl:px-6">
        <div className="w-full">
          <div className="text-main">
            <Title title={"Perfect Appliance Repair"} />
          </div>

          <p className="font-outfit text-[20px] font-bold text-center text-[#625c56]">
            Most trusted, honest, and experienced technicians at your service in Los Angeles County area.
          </p>
          <p className="mt-4 font-outfit text-[18px] text-[#625c56]">
            We are a family-owned company that has been in the appliance repair and maintenance industry for over 10
            years and our goal is to gain and maintain the respect and trust of our clients for the years to come.
            Because we are family owned and operated, we always provide personal attention and honest advice.
          </p>
          <p className="mt-4 font-outfit text-[18px] text-[#625c56]">
            At Perfect Repair we offer our customers prompt, professional and low-cost assistance to all their appliance
            repair and maintenance needs. Our skilled and well-trained technicians will arrive to you in a timely manner
            and fix any issues with almost any brand, model and age of appliance.
          </p>
          <p className="mt-4 font-outfit text-[18px] text-[#625c56]">
            Every customer’s individual needs and preferences are important to us at Perfect Repair. You can rest
            assured that we will treat your equipment with care and responsibility to ensure a long service life. We
            take pride in what we do, and your satisfaction and happiness is our top priority. Our 5-star customer
            reviews attest to us providing a professional and quality service to all our customers.
          </p>
          <p className="mt-4 font-outfit text-[18px] text-[#625c56]">
            We would love to earn the opportunity to have your trust and deliver you the best service in the appliance
            repair industry.
          </p>
          <ul className="mt-6">
            <li className="flex items-center flex-row mt-2 space-x-4 font-outfit sm:text-[19px] font-bold text-[#625c56]">
              {/* <AiOutlineCheck className='text-[#91765a]' />{' '} */}
              <span>&#x2713;</span>
              <span>Diagnostic Fee of $65 will be waived if we repair your appliance</span>
            </li>
            <li className="flex items-center flex-row mt-2 space-x-4 font-outfit sm:text-[19px] font-bold text-[#625c56]">
              {/* <AiOutlineCheck className='text-[#91765a]' />{' '} */}
              <span>&#x2713;</span>
              <span>Same day service by our professionals </span>
            </li>
            <li className="flex items-center flex-row mt-2 space-x-4 font-outfit sm:text-[19px] font-bold text-[#625c56]">
              {/* <AiOutlineCheck className='text-[#91765a]' />{' '} */}
              <span>&#x2713;</span>
              <span>Licensed Professionals</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
