import { BsYelp } from "react-icons/bs";
// import { CiTwitter } from "react-icons/ci";
// import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import { SiThumbtack } from "react-icons/si";
import { TfiInstagram } from "react-icons/tfi";

import DasSmall from "../Components/dasSmall";

import Logo1 from "../../assets/img/Repair/logo-expand-rouge123.jpg";

export default function Footer() {
  return (
    <>
      <div id={"contact"} className="w-full pb-10 bg-white md:pt-20">
        <div className="flex flex-wrap max-w-6xl mx-auto max-xl:px-6 md:flex-nowrap md:space-x-12">
          <div className="justify-center w-full max-sm:flex-col md:w-1/5 max-sm:mt-6 max-md:flex">
            <div>
              <img src={Logo1} className="max-md:w-64" alt="" />
            </div>
            <div>
              <div className="mt-3">
                <ul className="flex mt-8 space-x-3">
                  <li>
                    <a
                      href="https://instagram.com/_perfect.repair?igshid=MzRlODBiNWFlZA=="
                      className="text-[24px] text-[#000000] transition-all duration-300 hover:text-main"
                      target="_blank"
                    >
                      <TfiInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://biz.yelp.com/home/bPOKmsnoSdbLW3IgSlJVbQ/"
                      className="text-[24px] text-[#000000] transition-all duration-300 hover:text-main"
                      target="_blank"
                    >
                      <BsYelp />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.thumbtack.com/profile/services/423079038727045141/"
                      className="text-[24px] text-[#000000] transition-all duration-300 hover:text-main"
                      target="_blank"
                    >
                      <SiThumbtack />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-1/2 max-sm:w-full md:w-1/5 max-md:mt-8">
            <div className="text-main font-outfit font-bold text-[27px]">Contact</div>
            <div className="mt-3">
              <a
                href="tel:+18188100440"
                className="text-main font-outfit font-bold text-[27px] transition-all duration-300 hover:scale-50"
              >
                (818) 810-0440
              </a>
            </div>
            <div className="mt-3">
              <a
                href="mailto:aykperfectrepair@gmail.com"
                className="text-main font-outfit  text-[18px] transition-all duration-300 hover:text-main"
              >
                aykperfectrepair@gmail.com
              </a>
            </div>
            <div className="text-black font-bold font-outfit text-[15px] mt-3">
              <a href="https://goo.gl/maps/FMxyZJnyHPmvS1he8" target="_blank">
                <p>425 West Lomita Ave #7</p>
                <p>Glendale, CA 91204</p>
              </a>
            </div>
          </div>
          <div className="w-1/2 max-sm:w-full md:w-3/5 max-sm:mt-6 max-md:mt-8">
            <div className="text-main text-center font-outfit font-bold text-[27px]">Business Hours</div>
            <div>
              <DasSmall title={"Monday"} time={"09:00 am - 06:00 pm"} />
              <DasSmall title={"Tuesday"} time={"09:00 am - 06:00 pm"} />
              <DasSmall title={"Wednesday"} time={"09:00 am - 06:00 pm"} />
              <DasSmall title={"Thursday"} time={"09:00 am - 06:00 pm"} />
              <DasSmall title={"Friday"} time={"09:00 am - 06:00 pm"} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#14100c] py-8">
        <div className="max-w-6xl mx-auto max-xl:px-6 text-[#aba59f] text-[12px] text-center font-outfit">
          2023 &copy; All rights reserved. Web development by <a href="https://abov.am/">ABOV IT</a>
        </div>
      </div>
    </>
  );
}
