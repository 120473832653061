import Slider from "react-slick";

import Amana from "../../assets/img/brands/Amana-Logo.png";
import Electrolux from "../../assets/img/brands/Electrolux.svg.png";
import Fisherpaykel from "../../assets/img/brands/Fisherpaykel-logo.png";
import Frigidaire from "../../assets/img/brands/Frigidaire.svg.png";
import GeImg from "../../assets/img/brands/Ge.png";
import Haier from "../../assets/img/brands/Haier.svg.png";
import JennAir from "../../assets/img/brands/JennAir.png";
import Kitchenaid_logo from "../../assets/img/brands/Kitchenaid_logo.svg.png";
import LG from "../../assets/img/brands/lg.png";
import MyTag from "../../assets/img/brands/Maytag.jpg";
import OfferSmall from "./offerSmall";
import Samsung from "../../assets/img/brands/samsung.png";
import Whirlpool from "../../assets/img/brands/Whirlpool.png";

import Title from "./PropsComponents/Title";

export default function HomeOffer() {
  const brandImages = [
    { image: GeImg, brand: "General Electric" },
    { image: MyTag, brand: "MayTag" },
    { image: Electrolux, brand: "Electrolux" },
    { image: Haier, brand: "Haier" },
    { image: LG, brand: "LG" },
    { image: Frigidaire, brand: "Frigidaire" },
    { image: Samsung, brand: "Samsung" },
    { image: Whirlpool, brand: "WhirlPool" },
    { image: Kitchenaid_logo, brand: "KitchenAid" },
    { image: JennAir, brand: "Jennar" },
    { image: Amana, brand: "Amana" },
    { image: Fisherpaykel, brand: "FisherPayker" },
  ];
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 940,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 720,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="w-full Block__Padding bg-[#f5eee7] relative max-sm:pb-16">
      <div className="relative z-50 max-w-6xl  mx-auto max-xl:px-6">
        <div className="text-main">
          <Title title={"Brands We Service"} />
        </div>
        <div className="w-full">
          <Slider {...settings}>
            {brandImages.map((item, index) => (
              <OfferSmall key={index} image={item.image} brand={item.brand} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
