import { AiFillStar } from "react-icons/ai";

export default function OpinionSliderProps(props) {
  return (
    <div className="px-6">
      <div className="relative z-20 flex flex-col space-y-4">
        <div className="flex justify-center space-x-2">
          <AiFillStar className="text-[14px] text-main" />
          <AiFillStar className="text-[14px] text-main" />
          <AiFillStar className="text-[14px] text-main" />
          <AiFillStar className="text-[14px] text-main" />
          <AiFillStar className="text-[14px] text-main" />
        </div>
        <div className="text-white font-outfit sm:text-[18px] text-center">{props.description}</div>
        <div className="flex flex-col items-center">
          <div>
            <p className="text-center font-bold text-white font-outfit text-[12px]">{props.author}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
