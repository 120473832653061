import { BsArrowDown } from "react-icons/bs";
import { Link } from "react-scroll";

import BG1 from "../../assets/img/Repair/Whirlpool.jpeg";

export default function HomeFirst() {
  return (
    <div
      id="First_Home"
      className="Bg-Image w-full h-[400px]  sm:h-[100vh] max-sm:bg-cover  relative  after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[rgba(0,0,0,0)] flex  justify-center after:z-0 items-center"
    >
      <img src={BG1} className="absolute object-cover w-full h-full" />
      <div className="flex items-center justify-center">
        <div className="relative text-white max-w-xl  z-[1]">
          <h1 className="font-outfit drop-shadow-xl Custom_Shadow_Large shadow-black font-bold mt-2 sm:mt-6 text-[32px] sm:text-[30px] uppercase  md:text-[40px] xl:text-[60px] tracking-[1px] text-center md:leading-[50px] xl:leading-[70px]">
            Perfect Repair
          </h1>
          <p className="font-outfit  text-[16px] Custom_Shadow_Small mt-2 sm:mt-6 uppercase font-bold text-center tracking-[2px]">
            Los Angeles, California
          </p>
          <div className="flex justify-center mt-6">
            <Link
              to={"Book"}
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="px-[25px] py-[15px] bg-main text-white font-bold font-outfit uppercase text-[12px] cursor-pointer transition-all duration-300 hover:bg-white hover:text-black"
            >
              Book Appointment
            </Link>
          </div>
        </div>
        <Link
          to="About"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="animate-bounce w-12 h-12 text-white flex justify-center transition-all duration-500 items-center cursor-pointer   rounded-full border-[1px] border-white shadow-2xl absolute bottom-4 z-50 hover:text-main hover:border-main"
        >
          <div>
            <BsArrowDown />
          </div>
        </Link>
      </div>
    </div>
  );
}
