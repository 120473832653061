export default function OfferSmall(props) {
  return (
    <div className="p-2 max-sm:mb-5">
      <div className="bg-[#fdfbf8] py-[40px] px-[20px] group">
        <div className="flex justify-center mx-auto w-[230px] h-[120px]">
          <img
            src={props.image}
            className="opacity-75 object-contain transition-all duration-300 Image-Filter  group-hover:scale-105"
            alt={props.brand}
          />
        </div>
        {/* <div className='text-[#14100c] text-[24px] font-bold mt-2 text-center transition-all duration-300 group-hover:text-main'>
            {props.title}
          </div> */}
      </div>
    </div>
  );
}
